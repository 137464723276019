export const environment = {
  production: false,
  pubnub_topic: 'fi.clara.helpingapp',
  pubnub_environment: 'development',
  authUrl: 'https://mobilestaging.clara.fi/mobile/authenticate/',
  baseApiUrl: 'https://mobilestaging.clara.fi/mobile/1/',
  helpingAppUrl: 'helpingapp-staging.clara.fi',
  opentok_base_url: '',
  opentok_api_key: '47182754',
  secretPassphrase: 'Secret Passphrase For Helping App'
};
