import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, NavController, NavParams } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CalendarComponentOptions } from 'ion2-calendar';

import { Events } from '../../providers/events';
import { RegisterForTaskService } from '../../providers/register-for-task-service';
import { ToastService } from '../../providers/toast-service';
import { DateHelperService } from '../../providers/date-helper-service';
import { OwnTasksService } from '../../providers/own-tasks-service';

import { Task } from '../task/task.model';
import { TaskJob } from '../task/task-job.model';
import { TaskJobsTask } from '../task/task-jobs-task.model';
import { RegisterForTaskModel } from './register-for-task.model';
import { CacheService } from '../../providers/cache-service';
import { Storage } from '@ionic/storage';

const MONTHS = {
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    fi: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
    sv: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
};

const WEEKDAYS = {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    fi: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
    sv: ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
};

const actionRegister = 'register';
const actionCancel = 'cancel';

@Component({
    selector: 'app-page-register-for-task',
    templateUrl: 'register-for-task.component.html',
    styleUrls: ['register-for-task.component.scss']
})
export class RegisterForTaskComponent {

    @Input() onClick: () => void;
    @Input() registrationUnsubscribe: () => void;
    onDismissClick = this.navParams.get('onClick');
    registrationUnsubscribeEvent = this.navParams.get('registrationUnsubscribe');

    message: string;
    toastText: string;
    disabled = false;
    action: string;

    dates: moment.Moment[];
    type: 'string';
    optionsRange: CalendarComponentOptions = {
        pickMode: 'multi',
        weekStart: 1,
        monthFormat: 'MMMM YYYY',
        monthPickerFormat: MONTHS.en,
        weekdays: WEEKDAYS.en,
        showMonthPicker: false,
    };

    constructor(
        private ownTasksService: OwnTasksService,
        private cacheService: CacheService,
        private storage: Storage,
        public http: HttpClient,
        public navCtrl: NavController,
        public navParams: NavParams,
        public toastService: ToastService,
        public events: Events,
        public translate: TranslateService,
        public registerService: RegisterForTaskService,
        public dateHelper: DateHelperService,
        public alertCtrl: AlertController) {
        this.optionsRange.weekStart = this.translate.store.defaultLang === 'en' ? 0 : 1;
        this.optionsRange.monthPickerFormat = MONTHS[this.translate.store.defaultLang];
        this.optionsRange.weekdays = WEEKDAYS[this.translate.store.defaultLang];
    }

    task: Task = this.navParams.get('task');
    job: TaskJob = this.navParams.get('job');
    taskJob: TaskJobsTask = this.navParams.get('taskJob');

    singleOrMultipleTask  = true;
    bundle: boolean = this.task?.bundle_repetitions;

    dismiss() {
        this.registrationUnsubscribeEvent();
        this.onDismissClick();
    }

    ionViewWillEnter() {
        if (this.bundle) {
            this.singleOrMultipleTask = false;
        }
        this.action = this.job.isTaskRegistered || this.job.is_allocated ? actionCancel : actionRegister;
        this.optionsRange.weekStart = this.translate.store.defaultLang === 'en' ? 0 : 1;
        this.optionsRange.monthPickerFormat = MONTHS[this.translate.store.defaultLang];
        this.optionsRange.weekdays = WEEKDAYS[this.translate.store.defaultLang];
    }

    submit() {
        if (this.task.ongoing && this.action == actionRegister) {
            if (!this.dates) {
                this.toastService.showToast({text: 'registerForTask.calendar_title'});
                return;
            }
            if (this.message == null || this.message?.trim().length < 3) {
                this.toastService.showToast({text: 'registerForTask.mandatory_message'});
                return;
            }
        }

        this.onDismissClick();
        // prevents the user from clicking the button multiple times
        this.disabled = true;

        let data: RegisterForTaskModel;
        if (this.bundle) {
            data = {
                task_id:        this.taskJob.id,
                message:        this.message,
                action:         this.action,
                id:             this.job.id
            } as RegisterForTaskModel;
        } else {
            data = {
                task_id:        this.task.id,
                message:        this.message,
                action:         this.action,
                id:             this.job.id
            } as RegisterForTaskModel;
        }

        if (this.task.ongoing && this.dates) {
            data.suitable_dates = this.dates.map(date => {
                return {suitable_date: date.format('YYYY-MM-DD HH:MM')};
            });
        }

        this.registerService.sendMessage(data)
            // tslint:disable-next-line:no-shadowed-variable
        .subscribe(async (data) => {
            // this.navCtrl.back();
            await this.success(data);
            if (this.cacheService.cacheUpdate) {
                await this.updateUpcomingTasks();
            }
        }, (err) => {
            // If request had error, show it
            if (err.message && err.status !== 401) {
                this.toastService.showToast({text: err.message, duration: 3000, isI18n: false});
            } else {
                // Else show generic error message
                this.toastService.showToast({text: 'errors.unknown_error'});
            }
        });
    }

    /**
     * Update upcoming tasks in local cache after submit or cancel registration.
     */
    async updateUpcomingTasks() {
        await this.ownTasksService.setOwnTasks(false);
    }

    /**
     * Called upon successfully registering to task
     * @param data the updated task data returned from server
     */
    async success(data) {
        if (!this.job[`isTaskRegistered`]) {

            // We need the task jobs from returned data
            // Task jobs are passed back to selected-task in event
            let taskJob = data;
            if (data.tasks && data.tasks[0].task_jobs) {
                taskJob = data.tasks[0].task_jobs[0];
            }
            this.events.publish('registration:done', taskJob);

            let title;
            let content: string;
            this.translate.get('registerForTask.registration_success').subscribe(
                value => title = value
            );

            // If is_allocated is true, task has been confirmed automatically
            // So different message is shown
            if (taskJob.is_allocated) {
                this.translate.get('selectedTaskPage.allocated').subscribe(
                    value => content = value
                );
            } else {
                this.translate.get('registerForTask.acceptance_required').subscribe(
                    value => content = value
                );
            }

            this.events.publish('reload:selectedTask');

            const alert = await this.alertCtrl.create({
                header: title,
                message: content,
                buttons: ['OK'],
                mode: 'ios'
            });
            await alert.present();
        } else {
            this.events.publish('cancellation:done');
            this.toastText = 'registerForTask.cancel_success';
            this.toastService.showToast({text: this.toastText});
        }

        setTimeout(() => {
            this.job[`isTaskRegistered`] =
                !this.job[`isTaskRegistered`];
        }, 200);
    }

    taskDate(task: Task) {
        return this.dateHelper.formatTaskDate(task);
    }

    taskJobDate(task: Task, taskJobsTask: TaskJobsTask) {
        return this.dateHelper.formatTaskJobDate(task, taskJobsTask);
    }
}
